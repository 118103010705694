import React, { useState } from 'react';
import { Footer, HeaderSection, Undraw } from "../components";
import { Button } from '../components/Button';
import './Signup.scss';
import ReCAPTCHA from "react-google-recaptcha";

interface FormInput {
    value: string,
    valid: boolean,
    validator: RegExp
}

interface FormSelect {
    value: string,
    valid: boolean
}

interface SignupForm {
    name: FormInput,
    lastName: FormInput,
    email: FormInput,
    use: FormSelect,
    role: FormSelect
}

export const Signup = () => {

    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const [ form, setForm ] = useState({
        name: {
            value: '',
            valid: true,
            validator: /.{3,}/
        },
        lastName: {
            value: '',
            valid: true,
            validator: /.{3,}/
        },
        email: {
            value: '',
            valid: true,
            // eslint-disable-next-line
            validator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        use: {
            value: '',
            valid: true,
        },
        role: {
            value: '',
            valid: true,
        }
    } as SignupForm);
    
    const onChange = (field: keyof SignupForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if((form[field] as FormInput).validator.test(value)) {
                valid = true;
            } else {
                valid = false;
            }
            setForm({...form, [field]: {
                validator: validator,
                valid: valid,
                value: value
            }});
        }
    }

    const onSubmit = async () => {
        //const token = await recaptchaRef.current!.executeAsync();
        console.log(form);
    }

    return (
        <>
            <HeaderSection gradientColor1="#003780" gradientColor2="#80a1cc" backgroundImage="bg1" height="105vh">
                <>
                    <ReCAPTCHA
                        ref={ recaptchaRef }
                        size="invisible"
                        sitekey="6Ld53NgZAAAAAH1GDBpn7LK6lhtjIIWXMnyh6ae8"
                    />
                    <div className="row hide-sm" style={{
                        width: "100vw",
                        marginLeft: 0,
                        marginRight: 0,
                        backgroundSize: "cover"
                    }}>
                        <div className="col-md-7 col-lg-6 col-xl-6 align-self-center">
                            <div style={{
                                display: "block",
                                margin: "auto"
                            }}>
                                <Undraw name="my_app" width="70%"></Undraw>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-5 col-xl-5 align-self-center">
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Beta Signup</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please fill the following fields.</h4>
                            <br></br>
                            <form>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="name">Name</label>
                                    <input 
                                        onChange={ onChange("name") }
                                        type="text" 
                                        className={ `form-control form-input ${ form.name.valid ? '' : 'form-invalid' }` } 
                                        id="given-name" aria-describedby="name" placeholder="Name">
                                    </input>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="lastName">Last Name</label>
                                    <input 
                                        onChange={ onChange("lastName") }
                                        type="text" 
                                        className={ `form-control form-input ${ form.lastName.valid ? '' : 'form-invalid' }` } 
                                        id="family-name" aria-describedby="lastName" placeholder="Last Name">
                                    </input>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input 
                                        onChange={ onChange("email") }
                                        type="email" 
                                        className={ `form-control form-input ${ form.email.valid ? '' : 'form-invalid' }` } 
                                        id="email" aria-describedby="email" placeholder="example@abstrakty.com">
                                    </input>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="exampleInputEmail1">Where are you going to use Abstrakty?</label>
                                    <select className="custom-select form-input">
                                        <option disabled selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="exampleInputEmail1">What's your role?</label>
                                    <select className="custom-select form-input">
                                        <option disabled selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </form>
                            <br></br>
                            <div onClick={ onSubmit }>
                                <Button>Send</Button>
                            </div>
                        </div>
                            <br></br><br></br>
                    </div>

                    <div className="row hide-not-sm" style={{
                        minHeight: "100vh",
                        width: "100vw",
                        marginLeft: 0,
                        backgroundSize: "cover",
                        paddingBottom: "10vh",
                    }}>
                        <div className="col-12 align-self-center">
                            <div className="container">
                                <div style={{
                                    display: "block",
                                    margin: "auto",
                                }}>
                                    <Undraw name="empty" width="50vw"></Undraw>
                                    
                                </div>
                            </div>
                        </div>
                            
                        <div className="col-12 align-self-center" style={{
                            marginTop: "-15vh"
                        }}>
                            <div className="container">
                            <div className="spinner-border text-light" role="status" style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: "10vh",
                                    height: "10vh"
                            }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Searching</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please wait, we are taking you to the page you are looking.</h4>
                            </div>
                        </div>

                    </div>
                </>
            </HeaderSection>
            <Footer/>
        </>
    );
};