import { faArrowDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import './Section2.scss';

export const Section2 = () => {
    return (
        <div className="abs-section2-padding">

            <h2 className="abs-s2-title">Ejemplos de uso</h2>

            <div className="abs-section2-container">
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text-right">
                        <div>
                            <h4>Adaptativo</h4>
                            <p>
                                Utiliza Abstrakty en tu aplicación de videoconferencias favorita.
                                Por medio de nuestro plugin podrás disfrutar de todas las herramientas
                                y de la experiencia multiplataforma de Abstrakty.
                            </p>
                            <Link to="/player/adaptative">
                            <p className="abs-play-text">
                                Ve ahora como funciona
                                <FontAwesomeIcon icon={faArrowRight} className="abs-section2-icon-right"></FontAwesomeIcon>
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="abs-section2-content-image abs-section2-image">
                    <div className="abs-image-center">
                        <Link to="/player/adaptative">
                            <img className="abs-play-image" alt="Adaptative video" src="/assets/freelancer-square.png"></img>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="abs-section2-container gray">
                <div className="abs-section2-content-image-right abs-section2-image">
                    <div className="abs-image-center">
                    <Link to="/player/ai">
                            <img className="abs-play-image" alt="AI demo" src="/assets/businessman-square.png"></img>
                        </Link>
                    </div>
                </div>
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text">
                        <div>
                            <h4>Inteligencia artificial</h4>
                            <p>
                                Utiliza la inteligencia artificial de voz como herramienta
                                principal para interactuar con la aplicación de forma más
                                rápida y práctica.
                            </p>
                            <p>
                                Genera comandos de voz que te ayuden a localizar la información
                                que realmente necesitas, durante o al final de una
                                videoconferencia.
                            </p>
                            <Link to="/player/ai">
                            <p className="abs-play-text">
                                <FontAwesomeIcon icon={faArrowLeft} className="abs-section2-icon"></FontAwesomeIcon>
                                Ve ahora como funciona
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="abs-section2-container">
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text-right">
                        <div>
                            <h4>Playlist y etiquetas</h4>
                            <p>
                                Crea playlists y etiquetas que te permitan guardar y
                                compartir información valiosa dentro de una videoconferencia.
                                Todo en tiempo real sin esperar a que termine la sesión
                                de videoconferencia.
                            </p>
                            <Link to="/player/playlists">
                            <p className="abs-play-text">
                                Ve ahora como funciona
                                <FontAwesomeIcon icon={faArrowRight} className="abs-section2-icon-right"></FontAwesomeIcon>
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="abs-section2-content-image abs-section2-image">
                    <div className="abs-image-center">
                        <Link to="/player/playlists">
                            <img className="abs-play-image" alt="Playlist demo" src="/assets/student-square.png"></img>
                        </Link>
                    </div>
                </div>
            </div>



            <div className="abs-section2-container-mobile">
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text-mobile">
                        <div>
                            <h4>Adaptativo</h4>
                            <p>
                                Utiliza Abstrakty en tu aplicación de videoconferencias favorita.
                                Por medio de nuestro plugin podrás disfrutar de todas las herramientas
                                y de la experiencia multiplataforma de Abstrakty.
                            </p>
                            <Link to="/player/adaptative">
                            <p className="abs-play-text">
                                Ve ahora como funciona
                                <FontAwesomeIcon icon={faArrowDown} className="abs-section2-icon-right"></FontAwesomeIcon>
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="abs-section2-content-image-mobile abs-section2-image">
                    <div className="abs-image-center">
                    <Link to="/player/adaptative">
                        <img className="abs-play-image" alt="Adaptative demo" src="/assets/freelancer-square.png"></img>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="abs-section2-container-mobile">
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text-mobile">
                        <div>
                            <h4>Inteligencia artificial</h4>
                            <p>
                                Utiliza la inteligencia artificial de voz como herramienta
                                principal para interactuar con la aplicación de forma más
                                rápida y práctica.
                            </p>
                            <p>
                                Genera comandos de voz que te ayuden a localizar la información
                                que realmente necesitas, durante o al final de una
                                videoconferencia.
                            </p>
                            <Link to="/player/ai">
                            <p className="abs-play-text">
                                Ve ahora como funciona
                                <FontAwesomeIcon icon={faArrowDown} className="abs-section2-icon-right"></FontAwesomeIcon>
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="abs-section2-content-image-mobile abs-section2-image">
                    <div className="abs-image-center">
                    <Link to="/player/ai">
                        <img className="abs-play-image" alt="AI demo" src="/assets/businessman-square.png"></img>
                        </Link>
                    </div>
                </div>
            </div>


            <div className="abs-section2-container-mobile">
                <div className="abs-section2-content abs-section2-background">
                    <div className="abs-section2-content-text-mobile">
                        <div>
                            <h4>Playlist y etiquetas</h4>
                            <p>
                                Crea playlists y etiquetas que te permitan guardar y
                                compartir información valiosa dentro de una videoconferencia.
                                Todo en tiempo real sin esperar a que termine la sesión
                                de videoconferencia.
                            </p>
                            <Link to="/player/playlists">
                            <p className="abs-play-text">
                                Ve ahora como funciona
                                <FontAwesomeIcon icon={faArrowDown} className="abs-section2-icon-right"></FontAwesomeIcon>
                            </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="abs-section2-content-image-mobile abs-section2-image">
                    <div className="abs-image-center">
                    <Link to="/player/playlists">
                        <img className="abs-play-image" alt="Playlist demo" src="/assets/student-square.png"></img>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );
}