import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import './Footer.scss'
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from './Footer/LanguageSelector';

export const Footer = () => {

    const { t } = useTranslation('footer');

    return (
        <>
        <footer style={{
            backgroundColor: "#003780",
            padding: "2rem"
        }}>
            <br/>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 offset-md-1 offset-lg-1 offset-xl-1 align-self-center">
                    <Link to="/"><img className="footer-logo" src="assets/logo_white.png" alt="Abstrakty Logo" /></Link>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 align-self-center footer-social-media">
                    <p>
                        <a className="icons" href="https://facebook.com/abstrakty"><FontAwesomeIcon className="icons" icon={ faFacebookF } style={{
                            marginLeft: '10px',
                            marginRight: '10px'
                        }}/></a>
                        <a className="icons" href="https://m.me/abstrakty"><FontAwesomeIcon className="icons" icon={ faFacebookMessenger } style={{
                            marginLeft: '10px',
                            marginRight: '10px'
                        }}/></a>
                        <a className="icons" href="https://twitter.com/abstrakty"><FontAwesomeIcon className="icons" icon={ faTwitter } style={{
                            marginLeft: '10px',
                            marginRight: '10px'
                        }}/></a>
                    </p>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 align-self-center center-sm margin-sm hide-md">
                    <p className="links center-sm"><Link className="links" to="/terms">{t('terms')}</Link></p>
                    <p className="links center-sm"><Link className="links" to="/privacy">{t('privacy')}</Link></p>
                </div>
                <div className="col-12 hide-not-md" style={{
                    marginTop: '30px'
                }}>
                    <div className="row">
                        <div className="col-4 offset-1"><p className="links left"><Link className="links" to="/terms">{t('terms')}</Link></p></div>
                        <div className="col-4"><p className="links left"><Link className="links" to="/privacy">{t('privacy')}</Link></p></div>
                    </div>
                </div>
                <div className="col-sm-12 hide-not-sm">
                    <LanguageSelector align="center"/>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 offset-md-1 offset-lg-1 offset-xl-1 center-sm">
                    <p style={{
                        color: "#ffffff",
                        fontFamily: 'Poppins'
                    }}>{t('copyright')}</p>
                </div>
                <div className="col-3 hide-sm">
                    <LanguageSelector/>
                </div>
            </div>
            
        </footer>
        </>
    );
}