import React from 'react';
import { useTranslation } from "react-i18next"
import { SectionRight } from ".";

export const UnderConstruction = () => {
    const { t } = useTranslation('misc');
    return (
        <SectionRight drawing="under_construction">
            <>
                <h2 style={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    textAlign: 'center'
                }}>{ t('under-construction', "This section is under construction") }</h2>
                <h5 style={{
                    fontFamily: 'Poppins',
                    textAlign: 'center'
                }}>{ t('try-later', "Try again later.") }</h5>
            </>
        </SectionRight>
    );
}