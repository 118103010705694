import React from 'react';
import { Footer } from '../components/Footer';
import { SectionRight, SectionLeft, HeaderSection, Intro } from '../components';
import './Index.scss';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

const animatedScroll = () => {
    ReactGA.event({
        category: 'Landing',
        action: 'Discover More'
    });
    var winHeight = window.innerHeight;
    var current = window.scrollY;
    var interval = setInterval(() => {
        if(current >= winHeight) {
            clearInterval(interval);
            return;
        }
        current+= 15;
        window.scrollTo(0, current);
    }, 1);
}

export const Index = () => {

    const { t } = useTranslation('index');

    return (
        <>
            
            <HeaderSection gradientColor1="#003780" gradientColor2="#80a1cc" backgroundImage="bg1">
                <>
                    <Intro callback={ animatedScroll } />
                </>
            </HeaderSection>

            <SectionRight drawing="breaking_barriers">
                <>
                    <h2 className="header">{ t('s-1-1', 'You are not the only one') }</h2>
                    <p>{ t('s-1-2', 'Every day, more people is facing a world with more data that is arriving via digital devices. We are having a lot of meetings, sharing a lot of messages and files every day. All this data has become unmanageable.') }</p>
                    <p>{ t('s-1-3', "This is affecting us in ways we can't imagine, making us less productive, decreasing our information retention level, and creating a barrier for communication.") }</p>
                </>
            </SectionRight>

            <SectionLeft drawing="taking_notes" backgroundColor="#c2d6f2">
                <>
                    <h2 className="header">{ t('s-2-1', "Just imagine...") }</h2>
                    <p>{ t('s-2-2', "... a world where you can tag any moment, at the time it is generated.") }</p>
                    <p>{ t('s-2-3', "... have a tool that filters the information as you need it.") }</p>
                    <p>{ t('s-2-4', "This is not science fiction, the tool exists and its name is Abstrakty.") }</p>
                </>
            </SectionLeft>

            <SectionRight drawing="time_management" gradientColor1="#363861" gradientColor2="#51537e" backgroundImage="bg2">
                <>
                    <h2 className="header white">{ t('s-3-1', "Be productive") }</h2>
                    <p className="white">{ t('s-3-2', "Your time is valuable.") }</p>
                    <p className="white">{ t('s-3-3', "Don't even think about spending it reviewing many hours of content.") }</p>
                    <p className="white">{ t('s-3-4', "With Abstrakty you can get directly to the content you need, when you need it. Even you can tag it at the moment it's generated to see it later.") }</p>
                </>
            </SectionRight>

            <SectionLeft drawing="co-working" gradientColor1="#f3c25d" gradientColor2="#fce1a9" backgroundImage="bg3">
                <>
                    <h2 className="header">{ t('s-4-1', "Boost teamwork") }</h2>
                    <p>{ t('s-4-2', "Communication is a key factor in team success. Having a tool that gets you the accurate information that has been said from its original source is priceless.") }</p>
                    <p>{ t('s-4-3', "With Abstrakty you can share any moment of your meetings. Only share the relevant part that you want others to see. And of course, share your notes.") }</p>
                </>
            </SectionLeft>

            <SectionRight drawing="operating_system" gradientColor1="#363861" gradientColor2="#51537e" backgroundImage="bg4">
                <>
                    <h2 className="header white">{ t('s-5-1', "Manage your knowledge") }</h2>
                    <p className="white">{ t('s-5-2', "As a very wise person said once, knowledge is power. But how can you handle clusters of knowledge? How do you save all your meetings and keep them relevant?") }</p>
                    <p className="white">{ t('s-5-3', "Building a library of content has never been so easy. Save all your meetings on the cloud and when you need them, just look for them by a keyword. Imagine having all your meetings, sales, or lectures in your pocket.") }</p>
                </>
            </SectionRight>

            <SectionLeft drawing="team_spirit" gradientColor1="#4a4f95" gradientColor2="#676cad" backgroundImage="bg5">
                <>
                    <h2 className="header white">{ t('s-6-1', "Made for everyone") }</h2>
                    <p className="white">{ t('s-6-2', "Are you a business owner? A team leader? A team member? A teacher? A student? Abstrakty functionality is limitless.") }</p>
                    <p className="white">{ t('s-6-3', "It doesn't matter if you are new to the use of videocalls or if you are already using another platform and have a base of content. There's room for everyone at Abstrakty.") }</p>
                </>
            </SectionLeft>

            <SectionRight drawing="mobile_testing" backgroundColor="#e0e0e0">
                <>
                    <h2 className="header">{ t('s-7-1', "Are you ready for change?") }</h2>
                    <p>{ t('s-7-2', "Abstrakty is coming to your computer and mobile devices very soon.") }</p>
                    <p>{ t('s-7-3', "In the meantime, signup to the Abstrakty beta and become the first person around the globe, to meet the power you can give to your meetings and knowledge.") }</p>
                </>
            </SectionRight>

            <Footer/>
        </>
    );
}