import React from 'react';
import { useTranslation } from 'react-i18next';
import './Intro.scss'

export const Intro = (props: { callback: () => void }) => {
    const { t } = useTranslation('intro'); 
    return (
        <>
            <h3 style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    textAlign: "center",
                }}>{ t('phrase-1', "In the past you highlighted books") }</h3>
                <h1 className="display-4" style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontWeight: 700
                }}>{ t('phrase-2', "Nowadays,") }<br/>{ t('phrase-3', "how do you highlight media?") }</h1>
                <br></br>
                <button style={{
                    display: "block",
                    height: "7vh",
                    backgroundColor: "#ffffff",
                    border: 0,
                    borderRadius: "5px",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "rgba(0, 55, 128, 1)",
                    margin: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingBottom: "10px"
                }} onClick={ props.callback }>{ t('button', "Discover more") }</button>
        </>
    );
}