import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Section4.scss';

export const Section4 = () => {
    return (
        <>
            <div className="abs-section4-container">
                <div className="abs-section4-content-text">
                    <div>
                        <h2>Experiencias</h2>
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={{ span: 3, offset: 1 }}>
                                    <img className="abs-experience-picture" alt="Ana Hernandez Profile" src="/assets/experience_profile_1.png"></img>
                                </Col>
                                <Col sm={12} md={12} lg={7}>
                                    <p className="abs-experience-text">
                                        Abstrakty me ha permitido ser más productiva en mi trabajo,
                                        he podido gestionar y administrar mejor el contenido que día
                                        a día obtengo en mis juntas diarias con mi equipo mejorando
                                        nuestra comunicación de una forma increible.
                                    </p>
                                    <p className="abs-experience-name">Ana Hernández</p>
                                    <p className="abs-experience-company">CEO / Hey Social</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}