import React from 'react';
import { Header } from './Header';
import './Section.scss';

export const HeaderSection = (props: { 
    children: JSX.Element,
    height?: string,
    backgroundImage?: string,
    backgroundColor?: string,
    gradientColor1?: string,
    gradientColor2?: string
}) => {
    var background = undefined;
    if(props.gradientColor1 && props.gradientColor2 && props.backgroundImage) {
        background = `linear-gradient(90deg, ${ props.gradientColor1 }d9 0%, ${ props.gradientColor2 }d9 100%), url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundColor && props.backgroundImage) {
        background = `${ props.backgroundColor }d9, url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundImage) {
        background = `url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundColor) {
        background = `${ props.backgroundColor }`;
    } else if(props.gradientColor1 && props.gradientColor2) {
        background = `linear-gradient(90deg, ${ props.gradientColor1 } 0%, ${ props.gradientColor2 } 100%)`;
    }
    return (
        <>
            
            <div style={{
                height: props.height ? props.height : "100vh",
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                background: background ? background : "none",
                backgroundSize: "cover"
            }}>
                <Header/>
                <div className="row full-height-without-header" style={{
                    width: "100%",
                    margin: "0"
                }}>
                    <div className="col-12 align-self-center">
                        { props.children }
                    </div>
                </div>
            </div>
        </>
    );
}