import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Section3.scss';

export const Section3 = () => {
    return (
        <>
            <div className="abs-section3-container">
                <div className="abs-section3-text">
                    <Container>
                        <Row>
                            <Col
                                sm={12}
                                md={{ span: 8, offset: 2 }}
                                lg={{ span: 4, offset: 4 }}>
                                <h2>Se adapta a tu estilo de trabajo</h2>
                                <h6>Abstrakty se adapta a  tus  objetivos diarios de trabajo donde sea y cuando sea.</h6>
                            </Col>
                        </Row>
                        <div className="abs-section3-spacer"></div>
                        <Row>
                            <Col
                                sm={12}
                                md={4}>
                                <div className="abs-section3-col">
                                    <div className="abs-section3-icon">
                                        <img src="/assets/icon_web.png" alt="web"></img>
                                    </div>
                                    <h4>Plataforma web</h4>
                                    <p>Si prefieres mantener todo en la nube, en nuestra plataforma podrás gestionar tus archivos y videoconferencias directamente en la web.</p>
                                </div>
                            </Col>
                            <Col
                                sm={12}
                                md={4}>
                                <div className="abs-section3-col">
                                    <div className="abs-section3-icon">
                                        <img src="/assets/icon_app.png" alt="app"></img>
                                    </div>
                                    <h4>Aplicación móvil</h4>
                                    <p>Sacale el mayor provecho cuando quieras y dónde quieras con nuestra app instalada en tu dispositivo favorito.</p>
                                </div>
                            </Col>
                            <Col
                                sm={12}
                                md={4}>
                                <div className="abs-section3-col">
                                    <div className="abs-section3-icon">
                                        <img src="/assets/icon_plugin.png" alt="plugin"></img>
                                    </div>
                                    <h4>Plugin</h4>
                                    <p>Instala en tu aplicación favorita de videoconferencias el plugin de Abstrakty y disfruta de todas sus herramientas.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}