import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Index } from './pages/Index';
import { Terms } from './pages/Terms';
import { Privacy } from './pages/Privacy';
import ScrollTop from './components/ScrollTop';
import { Signup } from './pages/Signup';
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history';
import { Router } from './pages/Router';
import { NotFound } from './pages/NotFound';
import { Landing } from './pages/Landing';
import { Player } from './pages/Player';

const history: any = createBrowserHistory();
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  ReactGA.initialize('UA-180703209-1');
} else {
  if(window.location.hostname === 'abstrakty.com'
    || window.location.hostname === 'abstrakty.com.mx') {
    ReactGA.initialize('UA-180703209-2');
  } else {
    ReactGA.initialize('UA-180703209-1');
  }
}
ReactGA.pageview(window.location.pathname);
history.listen(() => {
    ReactGA.pageview(window.location.pathname);
    console.log(window.location.pathname)
});

function App() {
  
  return (
    <BrowserRouter>
      <ScrollTop>
        <Switch>

          <Route path="/terms" component={ Terms }></Route>
          <Route path="/privacy" component={ Privacy } ></Route>
          <Route path="/beta-signup" component={ Signup } ></Route>
          <Route path="/not-found" component={ NotFound } ></Route>
          <Route path="/landing" component={ Index }></Route>
          <Route path="/player/:id" component={ Player }></Route>
          <Route path="/:id" component={ Router } ></Route>
          <Route path="/" component={ Landing } ></Route>

        </Switch>

      </ScrollTop>
    </BrowserRouter>
  );
}

export default App;
