import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { UnderConstruction } from '../components/UnderConstruction';

export const Privacy = () => {
    return (
        <>
            <div className="section1-legal">
                <Header/>
                <div style={{
                    height: "8vh"
                }}></div>
                <h1 style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "3.5em",
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                    marginBottom: "0"
                }}>Privacy Policy</h1>
                <br></br>
                <h4 style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    textAlign: "center",
                    marginTop: "0"
                }}>At Abstrakty we care about your data. <br/> It's important for you to undersand how we capture, use and protect your data.</h4>
            </div>
            <UnderConstruction/>
            <Footer/>
        </>
    );
}