import React from 'react';
import './Section1.scss';

export const Section1 = () => {
    return (
        <>
            <div className="abs-section1-container">
                <div className="abs-section1-content abs-section1-image">

                </div>
                <div className="abs-section1-content abs-section1-background">
                    <div className="abs-section1-content-text">
                        <div>
                            <h2>Tu aliado en contenido audiovisual</h2>
                            <p>
                                Abstrakty es una plataforma que acorta, simplifica y ayuda
                                a compartir contenido audiovisual creado en tiempo real durante
                                o después de videoconferencias, propiedad del usuario
                                o de un externo.
                            </p>
                            <p>
                                Esto se logra gracias a que Abstrakty utiliza distintas
                                herramientas de edición y marcadores para la generación de
                                clips con transcripción y notas incluidas que se puedan
                                compartir.
                            </p>
                            {/*
                            <h5>
                                <FontAwesomeIcon icon={faArrowRight} className="abs-section1-icon"></FontAwesomeIcon>
                                Prueba gratis por 15 días
                            </h5>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}