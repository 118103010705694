export const isDev = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return true;
    } else {
        if(window.location.hostname === 'abstrakty.com') {
            return false;
        } else {
            return true;
        }
    }
}