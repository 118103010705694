import React from 'react';

export const Undraw = (props: { name: string, height?: string, width?: string }) => {
    return (
        <img alt={ props.name } src={`undraw/${ props.name }.svg`} style={{
            height: props.height ? props.height : 'initial',
            width: props.width ? props.width : 'initial',
            margin: "auto",
            display: "block"
        }} />
    );
}