import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from './Button';
import './HeaderSection.scss';
import ReactGA from 'react-ga';

const animatedScroll = () => {
    ReactGA.event({
        category: 'Landing',
        action: 'Discover More'
    });
    var winHeight = window.innerHeight;
    var current = window.scrollY;
    var interval = setInterval(() => {
        if(current >= winHeight) {
            clearInterval(interval);
            return;
        }
        current+= 10;
        window.scrollTo(0, current);
    }, 1);
}

export const HeaderSection = () => {
    return (
        <>
            <div className="abs-header-background">
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={3}>
                            <img src="/assets/logo_white_bicolor.png" alt="abstrakty-landing" className="abs-header-logo"></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} lg={6} >
                            <h1 className="abs-header-title">La herramienta perfecta para videollamadas</h1>
                            <h3 className="abs-header-subtitle" style={{
                                fontSize: '1.7rem',
                                fontWeight: 600
                            }}>Destaca el valor de tus ideas</h3>
                            <p className="abs-header-text">Gestiona, administra y comparte contenido audiovisual de forma rápida y precisa en tiempo real.</p>
                            <div onClick={animatedScroll}><Button>DESCUBRE CÓMO HACERLO</Button></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
