import React from 'react';
import './Index.scss';
import { Footer, HeaderSection, Section1, Section2, Section3, Section4 } from '../components/Landing';

export const Landing = () => {

    return (
        <>
            <HeaderSection></HeaderSection>
            <Section1></Section1>
            <Section2></Section2>
            <Section3></Section3>
            <Section4></Section4>
            <Footer></Footer>
        </>
    );
}