import React from 'react';
import { Footer } from '../components/Footer';
import { HeaderSection, Undraw } from '../components';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';

export const NotFound = () => {
    return (
        <>
            <HeaderSection gradientColor1="#003780" gradientColor2="#80a1cc" backgroundImage="bg1">
                <>
                    <div className="row hide-sm" style={{
                        width: "100vw",
                        marginLeft: 0,
                        marginRight: 0,
                        backgroundSize: "cover"
                    }}>
                        <div className="col-md-7 col-lg-6 col-xl-6 align-self-center">
                            <div style={{
                                display: "block",
                                margin: "auto"
                            }}>
                                <Undraw name="empty" width="80%"></Undraw>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-5 col-xl-5 align-self-center">
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Not Found</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please wait, we are taking you to the page you are looking.</h4>
                            <br></br>
                            <Link to="/"><Button>Go to abstrakty.com</Button></Link>
                        </div>
                            <br></br><br></br>
                    </div>

                    <div className="row hide-not-sm" style={{
                        minHeight: "100vh",
                        width: "100vw",
                        marginLeft: 0,
                        backgroundSize: "cover",
                        paddingBottom: "10vh",
                    }}>
                        <div className="col-12 align-self-center">
                            <div className="container">
                                <div style={{
                                    display: "block",
                                    margin: "auto",
                                }}>
                                    <Undraw name="empty" width="50vw"></Undraw>
                                    
                                </div>
                            </div>
                        </div>
                            
                        <div className="col-12 align-self-center" style={{
                            marginTop: "-15vh"
                        }}>
                            <div className="container">
                            <div className="spinner-border text-light" role="status" style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: "10vh",
                                    height: "10vh"
                            }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Searching</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please wait, we are taking you to the page you are looking.</h4>
                            </div>
                        </div>

                    </div>
                </>
            </HeaderSection>
            <Footer/>
        </>
    );
}