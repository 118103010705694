interface Video {
    id: string;
    youtubeId: string;
}

const videos: Video[] = [
    { id: 'playlists', youtubeId: 'Z4yfxOQXqfQ' },
    { id: 'ai', youtubeId: 'pFD6IHBoRiU' },
    { id: 'adaptative', youtubeId: 'WFSTEi_btf8' }
]

const getVideoURL = (id: string): string => {
    const res = videos.find(x => x.id === id);
    if(res) {
        return res.youtubeId;
    } else {
        return "";
    }
}

export default getVideoURL;