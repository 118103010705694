import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelector = (props: { 
    align?: "right" | "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "center" | "end" | "justify" | "left" | "match-parent" | "start" | undefined 
}) => {

    const { i18n } = useTranslation();
    var changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        window.scrollTo(0, 0);
    }

    return (
        <>
            <p style={{
                        color: "#ffffff",
                        fontFamily: 'Poppins',
                        textAlign: props.align ? props.align : 'right',
                        lineHeight: '1'
            }}>
                <span className="flag" role="img" aria-label="spanish" onClick={ () => changeLanguage('es') }>🇲🇽</span>
                <span className="flag" role="img" aria-label="english" onClick={ () => changeLanguage('en') }>🇺🇸</span>
            </p>
        </>
    );
}