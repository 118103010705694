import React from 'react';
import './Button.scss'

export const Button = (props: 
    { 
        height?: string,
        children: JSX.Element | string
    } = 
    { 
        height: "100%",
        children: <></>
    }) => {
    return (

        <button className="abstrakty-button" style={{
            backgroundColor: "#ffffff",
            color: "rgba(0, 55, 128, 1)",
        }}>{ props.children }</button>
    );
}

export enum ButtonType {
    Anchor,
    Action,
    Link,
    None
}