import React, { useState } from 'react';
import { Footer } from '../components/Footer';
import { HeaderSection, Undraw } from '../components';
import { isDev } from '../services/development';
import { Redirect } from 'react-router';

export const Router = (props: any) => {
    var [ notFound, setNotFound ] = useState(false);
    if(!notFound) {
        var id = props.match.params.id;
        var url = "https://landing.abstrakty.net/"
        if(isDev()) {
            url += "dev";
        } else {
            url += "api";
        }
        url += "/route/" + id;
        fetch(url).then((response) => {
            if(response.status === 404) {
                setNotFound(true);
            } else {
                response.json().then(data => {
                    document.location.href = data.url;
                });
            }
        }).catch(e => {
            console.log(e);
        });
    }
    
    return (
        <>
            {
                notFound &&
                <Redirect to="/not-found"></Redirect>
            }
            <HeaderSection gradientColor1="#003780" gradientColor2="#80a1cc" backgroundImage="bg1">
                <>
                    <div className="row hide-sm" style={{
                        width: "100vw",
                        marginLeft: 0,
                        marginRight: 0,
                        backgroundSize: "cover"
                    }}>
                        <div className="col-md-7 col-lg-6 col-xl-6 align-self-center">
                            <div style={{
                                display: "block",
                                margin: "auto"
                            }}>
                                <Undraw name="share_link" width="80%"></Undraw>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-5 col-xl-5 align-self-center">
                            <div className="spinner-border text-light" role="status" style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: "10vh",
                                    height: "10vh"
                            }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Searching</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please wait, we are taking you to the page you are looking.</h4>
                        </div>
                            <br></br><br></br>
                    </div>

                    <div className="row hide-not-sm" style={{
                        minHeight: "100vh",
                        width: "100vw",
                        marginLeft: 0,
                        backgroundSize: "cover",
                        paddingBottom: "10vh",
                    }}>
                        <div className="col-12 align-self-center">
                            <div className="container">
                                <div style={{
                                    display: "block",
                                    margin: "auto",
                                }}>
                                    <Undraw name="share_link" width="50vw"></Undraw>
                                    
                                </div>
                            </div>
                        </div>
                            
                        <div className="col-12 align-self-center" style={{
                            marginTop: "-15vh"
                        }}>
                            <div className="container">
                            <div className="spinner-border text-light" role="status" style={{
                                    display: 'block',
                                    margin: 'auto',
                                    width: "10vh",
                                    height: "10vh"
                            }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <br></br>
                            <h1 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                fontWeight: 700
                            }}>Searching</h1>
                            <h4 style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                textAlign: "center",
                            }}>Please wait, we are taking you to the page you are looking.</h4>
                            </div>
                        </div>

                    </div>
                </>
            </HeaderSection>
            <Footer/>
        </>
    );
}