import React from 'react';
import { Undraw } from './Undraw';

export const SectionRight = (props: { 
    children: JSX.Element,
    drawing: string,
    height?: string,
    backgroundImage?: string,
    backgroundColor?: string,
    gradientColor1?: string,
    gradientColor2?: string
}) => {
    var background = undefined;
    if(props.gradientColor1 && props.gradientColor2 && props.backgroundImage) {
        background = `linear-gradient(90deg, ${ props.gradientColor1 }d9 0%, ${ props.gradientColor2 }d9 100%), url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundColor && props.backgroundImage) {
        background = `${ props.backgroundColor }d9, url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundImage) {
        background = `url('/assets/${ props.backgroundImage }.jpg')`;
    } else if(props.backgroundColor) {
        background = `${ props.backgroundColor }`;
    } else if(props.gradientColor1 && props.gradientColor2) {
        background = `linear-gradient(90deg, ${ props.gradientColor1 } 0%, ${ props.gradientColor2 } 100%)`;
    }
    return (
        <>
            <div className="row hide-sm" style={{
                height: props.height ? props.height : "100vh",
                marginLeft: 0,
                marginRight: 0,
                background: background ? background : "none",
                backgroundSize: "cover"
            }}>
                <div className="col-md-4 col-lg-5 col-xl-5 offset-1 align-self-center">
                    { props.children }
                </div>

                <div className="col-md-7 col-lg-6 col-xl-6 align-self-center">
                    <div style={{
                        display: "block",
                        margin: "auto"
                    }}>
                        <Undraw name={ props.drawing } width="80%"></Undraw>
                    </div>
                </div>
            </div>

            <div className="row hide-not-sm" style={{
                minHeight: "100vh",
                width: "100vw",
                marginLeft: 0,
                background: background ? background : "none",
                backgroundSize: "cover",
                paddingBottom: "10vh",
                paddingTop: "10vh"
            }}>
                <div className="col-12 align-self-center">
                    <div className="container">
                        <div style={{
                            display: "block",
                            margin: "auto",
                            marginBottom: "5vh"
                        }}>
                            <Undraw name={ props.drawing } width="70vw"></Undraw>
                        </div>
                    </div>
                </div>
                    
                <div className="col-12 align-self-center">
                    <div className="container">
                        { props.children }
                    </div>
                </div>

            </div>
        </>
    );
}