import React from 'react'
import { Button } from './Button';
import './Section.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Header = () => {

    const { t } = useTranslation('misc');

    return (
        <header>
            <div className="row" style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                paddingRight: "25px",
                paddingLeft: "25px",
                margin: 0,
                width: "100vw"
            }}>
                <div className="col-6 col-sm-6 col-md-8 col-lg-9 col-xl-10">
                        <img src="assets/logo_white.png" style={{
                            height: '5vh'
                        }} alt="Abstrakty Logo" />
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="row hide-sm" style={{
                        paddingRight: "20px"
                    }}>
                        <div className="col-4">
                            <p className="header-icons" style={{
                                marginRight: "10px"
                            }}>
                                <a href="mailto:hola@abstrakty.com"><FontAwesomeIcon icon={ faEnvelope } /></a>
                            </p>
                        </div>
                        <div className="col-5">
                            <Link to="beta-signup"><Button>{ t('signup', "Signup") }</Button></Link>
                        </div>
                    </div>
                    <div className="row hide-not-sm">
                        <div className="col-12">
                            <p className="header-icons">
                                <a href="mailto:hola@abstrakty.com"><FontAwesomeIcon icon={ faEnvelope } /></a>
                                <Link to="/beta-signup"><FontAwesomeIcon icon={ faSignInAlt } /></Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}